import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";
import { gsap } from "gsap";
import "./assets/styles/App.scss";
import Header from "./components/header";
import { debounce } from "./util/debounce";

import Home from "./pages/home";

const routes = [
  { path: "*", name: "Home", Component: Home },
];

function App() {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });

  const [animationComplete, setAnimationComplete] = useState(false);

  const completeAnimation = () => {
    setAnimationComplete(true);
  };


  useEffect(() => {
    // prevents flashing
    gsap.to("body", 0, { css: { visibility: "visible" } });

    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 100);

    window.addEventListener("resize", debouncedHandleResize);

    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  useEffect(() => {
    const vh = dimensions.height * 0.01;

    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }, [dimensions.width, dimensions.height]);

  return (
    <>
      <main className='main'>
        <Header animationComplete={animationComplete} />
        {routes.map(({ path, Component }) => (
          <Route key={path} exact path={path}>
            <Component animationComplete={animationComplete} completeAnimation={completeAnimation} />
          </Route>
        ))}
      </main>
    </>
  );
}

export default App;
