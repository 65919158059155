import React, { useEffect } from "react";
import IntroOverlay from "../components/introOverlay";
import Banner from "../components/banner";
import Cases from "../components/cases";
import { homeAnimation } from "../animations/homeAnimation";

const Home = ({ animationComplete, completeAnimation }) => {

  useEffect(() => {
    homeAnimation(completeAnimation);
  }, []);

  return (
    <>
      {animationComplete === false ? <IntroOverlay /> : <></>}
      <Banner />
      <Cases />
    </>
  );
};

export default Home;
