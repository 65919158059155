import React from "react";
import { withRouter } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";

// Define reducer

const Header = () => {
  return (
    <div className='header'>
      <div className='container'>
        <div className='row v-center space-between'>
          <div className='logo'>
            <Logo />
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(Header);
