import React from "react";

const caseStudies = [
  {
    id: 1,
    title: "Custom software development",
    img: "software-development.jpg",
    desc: "We develope custom software for small to medium scale platforms. Built by the people for the people."
  },
  {
    id: 2,
    title: "Websites & e-commerce solutions",
    img: "websites-ecommerce.jpg",
    desc: "We provide web solutions for helping your business to grow in concert with top notch UI/UX design."
  },
  {
    id: 3,
    title: "Mobile application development",
    img: "mobile-app.jpg",
    desc: "We deliver Android and iOS applications using native and hybrid technologies that can reach millions of users."
  }
];

const Cases = () => {
  return (
    <section className='cases'>
      <div className='container-fluid'>
        <div className='row'>
          {caseStudies.map(caseItem => (
            <div className='case' key={caseItem.id}>
              <div className='case-details'>
                <h2>{caseItem.title}</h2>
                <p>{caseItem.desc}</p>
              </div>
              <div className='case-image'>
                <img
                  src={require(`../assets/images/${caseItem.img}`)}
                  alt={caseItem.title}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Cases;
