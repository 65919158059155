import React from "react";
import { ReactComponent as RightArrow } from "../assets/icons/arrow-right.svg";

const Banner = () => {
  return (
    <section className='banner'>
      <div className='container'>
        <div className='row'>
          <h1>
            <div className='line'>
              <span>We build software</span>
            </div>
            <div className='line'>
              <span>for people.</span>
            </div>
          </h1>
          <div className='btn-row'>
            <a className='btn' href='mailto:info@nelimeedia.ee'>
              <span>Get in touch</span>
              <span className="hover-text">info@nelimeedia.ee</span>
              <RightArrow />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
